import React from "react";
import colors from "../../styles/colors";
// import * as Component from "../all";
import { HiOutlineHome } from "react-icons/hi";
import { MdAlternateEmail, MdTaskAlt } from "react-icons/md";
import { RiFileUserLine } from "react-icons/ri";
import { BiCodeAlt } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { scrollTo } from "../../features/scrollSlice";

export default function Sidebar({ from_nav, setShowSidebar }) {
	const dispatch = useDispatch();

	const scrollToComponent = (component) => {
		if (from_nav === true) {
			setShowSidebar(false);
		}

		dispatch(scrollTo(component));
	};

	return (
		<div
			style={{
				backgroundColor: colors.black_2,
			}}
			className={`Sidebar h-full 
			${from_nav === false && "h-[calc(100%_-_50px)]"}
			 w-[200px] flex flex-col items-center justify-start`}
		>
			{from_nav === true && (
				<div
					style={{
						backgroundColor: colors.gray_4,
					}}
					className="h-[60px] w-full"
				></div>
			)}
			<div className="w-full mt-20">
				<h1
					style={{ color: colors.gray_2 }}
					className="text-[14px] py-2 pl-5 font-medium cursor-default flex hover:!text-white duration-150 ease-in"
					onClick={() => scrollToComponent("banner")}
				>
					<HiOutlineHome size={18} /> &nbsp; &nbsp; &nbsp; HOME
				</h1>

				<h1
					style={{ color: colors.gray_2 }}
					className="text-[14px] py-2 pl-5 font-medium cursor-default flex hover:!text-white duration-150 ease-in"
					onClick={() => scrollToComponent("services")}
				>
					<MdTaskAlt size={18} /> &nbsp; &nbsp; &nbsp; SERVICES
				</h1>

				<h1
					style={{ color: colors.gray_2 }}
					className="text-[14px] py-2 pl-5 font-medium cursor-default flex hover:!text-white duration-150 ease-in"
					onClick={() => scrollToComponent("resume")}
				>
					<RiFileUserLine size={18} /> &nbsp; &nbsp; &nbsp; RESUME
				</h1>

				<h1
					style={{ color: colors.gray_2 }}
					className="text-[14px] py-2 pl-5 font-medium cursor-default flex hover:!text-white duration-150 ease-in"
					onClick={() => scrollToComponent("works")}
				>
					<BiCodeAlt size={18} /> &nbsp; &nbsp; &nbsp; WORKS
				</h1>

				<h1
					style={{ color: colors.gray_2 }}
					className="text-[14px] py-2 pl-5 font-medium cursor-default flex hover:!text-white duration-150 ease-in"
					onClick={() => scrollToComponent("contact")}
				>
					<MdAlternateEmail size={18} /> &nbsp; &nbsp; &nbsp; CONTACT
				</h1>
			</div>
		</div>
	);
}
