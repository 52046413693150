import React from "react";

export default function Divider({ color, ...props }) {
	return (
		<div
			style={{
				borderTop: `2px solid ${color}`,
			}}
			{...props}
		></div>
	);
}
