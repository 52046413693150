import React, { useEffect, useState } from "react";
import colors from "../../styles/colors";
import * as Component from "..";
import { AnimatePresence, motion } from "framer-motion";
import { BiChevronRight } from "react-icons/bi";
import { WORKS_PROJECTS_LIST, GITHUB_REPOS_URL } from "../../data";

export default function Works({ reference }) {
	const [selected, setSelected] = useState("all");
	const [filtered, setFiltered] = useState(WORKS_PROJECTS_LIST);

	useEffect(() => {
		if (selected === "all") {
			setFiltered(WORKS_PROJECTS_LIST);
		} else {
			setFiltered(WORKS_PROJECTS_LIST?.filter?.((x) => x.tech === selected));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected]);

	return (
		<div
			ref={reference}
			className="pb-10 pt-10 px-2 lg:px-6 min-h-[300px] h-fit duration-300 ease-in select-none"
		>
			<h1
				style={{ color: colors.white_1 }}
				className="text-lg font-semibold tracking-wide pl-2"
			>
				Works
			</h1>

			<div
				style={{ color: colors.gray_5 }}
				className="flex items-center flex-wrap mt-8 pl-2"
			>
				<h1
					className={`text-sm font-medium tracking-wide cursor-pointer duration-150 hover:opacity-80 ${
						selected === "all" && " text-white"
					}`}
					onClick={() => setSelected("all")}
				>
					All
				</h1>

				<h1
					className={`text-sm ml-3 font-medium tracking-wide cursor-pointer duration-150 hover:opacity-80 ${
						selected === "react" && " text-white"
					}`}
					onClick={() => setSelected("react")}
				>
					React & Firebase
				</h1>

				<h1
					className={`text-sm ml-3 font-medium tracking-wide cursor-pointer duration-150 hover:opacity-80 ${
						selected === "mern" && " text-white"
					}`}
					onClick={() => setSelected("mern")}
				>
					MERN
				</h1>

				<h1
					className={`text-sm ml-3 font-medium tracking-wide cursor-pointer duration-150 hover:opacity-80 ${
						selected === "next" && " text-white"
					}`}
					onClick={() => setSelected("next")}
				>
					Next JS
				</h1>

				<h1
					className={`text-sm ml-3 font-medium tracking-wide cursor-pointer duration-150 hover:opacity-80 ${
						selected === "php" && " text-white"
					}`}
					onClick={() => setSelected("php")}
				>
					PHP
				</h1>
			</div>

			<motion.div layout className="w-full mt-2 flex flex-row flex-wrap">
				<AnimatePresence>
					{filtered?.map?.((project, index) =>
						project.more ? (
							<motion.div
								key={`${project.name} ${index}`}
								className="p-2 aspect-square w-1/2 md:w-1/3 lg:min-w-[250px] lg:w-1/2 xl:w-1/3 flex items-center justify-center"
								layout
								transition={{ duration: 0.5, ease: "easeInOut" }}
							>
								<a
									href={GITHUB_REPOS_URL}
									target={"_blank"}
									rel="noreferrer"
									style={{
										color: colors.gray_5,
									}}
									className="py-2 text-sm font-bold uppercase tracking-wide flex items-center hover:!text-white duration-300 ease-in-out"
								>
									More Projects&nbsp;
									<BiChevronRight size={20} />
								</a>
							</motion.div>
						) : (
							<Component.CardWork
								key={`${project.name} ${index}`}
								project={project}
							/>
						)
					)}
				</AnimatePresence>
			</motion.div>
		</div>
	);
}
