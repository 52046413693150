import React from "react";
import colors from "../../styles/colors";
import * as Component from "..";
import { CONTACT_LIST } from "../../data";

export default function Contact({ reference }) {
	return (
		<div ref={reference} className="pb-10 pt-10 px-2 lg:px-6">
			<div className="w-full">
				<h1
					style={{ color: colors.white_1 }}
					className="text-lg font-semibold tracking-wide pl-2"
				>
					Contact Information
				</h1>

				{/* cards */}
				<div className="w-full mt-5 items-stretch flex flex-row justify-between flex-wrap">
					{CONTACT_LIST?.map?.((item, index) => (
						<Component.CardContact key={index} item={item} />
					))}
				</div>
			</div>
		</div>
	);
}
