import React from "react";
import colors from "../../../styles/colors";
import "./CardService.css";

export default function CardService({ heading, start, points, end }) {
	return (
		<div className="card min-w-[300px] flex-1 p-2">
			<div
				style={{
					backgroundColor: colors.black_2_,
					color: colors.gray_2,
				}}
				className="card-inner relative rounded-sm h-full px-7 py-9 overflow-hidden z-20"
			>
				{/* heading */}
				<h1
					style={{ color: colors.white_1 }}
					className="font-medium mb-4 tracking-wide"
				>
					{heading}
				</h1>

				{/* start */}
				<p className="text-sm text-justif">{start}</p>

				{/* points */}
				<ul className="points-list list-inside my-3 mx-2">
					{points &&
						points?.map?.((item, index) => (
							<li key={index} className="text-sm font-light">
								&nbsp; {item}
							</li>
						))}
				</ul>

				{/* end */}
				<p className="text-sm text-justif">{end}</p>

				{/* animation circles */}
				<div className="animation-circles absolute top-0 right-0 translate-x-1/2 -translate-y-1/2">
					<div>
						<div>
							<div></div>
						</div>
					</div>
				</div>
				<div className="animation-circles absolute bottom-0 right-0 translate-x-1/2 translate-y-1/2">
					<div>
						<div>
							<div></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
