import React, { useRef } from "react";
import * as Component from "..";
import colors from "../../styles/colors";
import { BiCheckDouble } from "react-icons/bi";
import { GiCloudDownload } from "react-icons/gi";
import {
	MY_RESUME,
	DETAILS_PROFILE_IMAGE,
	DETAILS_TECHNOLOGY,
	DETAILS_SKILLS,
	SOCIAL_ACCOUNTS,
} from "../../data";

export default function Details({ from_nav, setShowDetails }) {
	const profileImageRef = useRef();
	const changePosition = (e) => {
		var x = e.nativeEvent.offsetX;
		var y = e.nativeEvent.offsetY;

		if (x < e.nativeEvent.srcElement.offsetWidth / 2) {
			x = x - e.nativeEvent.srcElement.offsetWidth;
		}

		if (y < e.nativeEvent.srcElement.offsetHeight / 2) {
			y = y - e.nativeEvent.srcElement.offsetHeight;
		}

		profileImageRef.current.style.marginLeft = x / 110 + "%";
		profileImageRef.current.style.marginRight = -x / 110 + "%";
		profileImageRef.current.style.marginTop = y / 110 + "%";
		profileImageRef.current.style.marginBottom = -y / 110 + "%";
	};
	const resetPosition = () => {
		profileImageRef.current.style.margin = `0px`;
	};

	return (
		<div
			style={{ backgroundColor: colors.black_2 }}
			className="Details relative w-[320px] h-full select-none"
		>
			<div className="h-full overflow-auto scroll-smooth pb-8">
				{/* TOP CONTAINER */}
				<div
					style={{
						backgroundColor: colors.black_2_,
					}}
					className="DetailsTop sticky h-[260px] w-full top-0 left-0 flex flex-col justify-center items-center z-40"
				>
					{/* image container */}
					<div
						style={{
							backgroundColor: colors.gray_4,
							boxShadow: "0 0 25px 0 rgb(0 0 0 / 80%)",
						}}
						className="relative h-36 w-36 mx-auto rounded-full p-4 flex justify-center items-center"
						onMouseMove={(e) => changePosition(e)}
						onMouseLeave={() => resetPosition()}
					>
						{/* image */}
						<div
							className="h-full w-full rounded-full ease-in duration-75"
							style={{
								// backgroundColor: colors.white_1,
								backgroundImage: `url(${DETAILS_PROFILE_IMAGE})`,
								backgroundPosition: "center",
								backgroundSize: "contain",
								backgroundRepeat: "no-repeat",
							}}
							ref={profileImageRef}
						></div>

						{/* available circle */}
						<div className="absolute right-5 bottom-4">
							<Component.AvailableCircle />
						</div>
					</div>

					{/* name */}
					<h1
						style={{
							color: colors.white_1,
						}}
						className="w-full text-center text-lg font-semibold mt-3 duration-300 ease-in cursor-default hover:!text-[#007CED]"
					>
						Abdul Haseeb
					</h1>

					{/* skills */}
					<h2
						style={{ color: colors.gray_2 }}
						className="w-full text-center text-sm font-light mt-1 cursor-default"
					>
						Full-stack Developer
						<br />
						Software Engineer
					</h2>
				</div>

				{/* INFO CONTAINER */}
				<div className="px-8 py-6">
					{/* age */}
					<div className="w-full flex justify-between items-center mb-2">
						<h1
							style={{ color: colors.white_1 }}
							className="text-[12px] font-semibold cursor-default"
						>
							Age:
						</h1>

						<h2
							style={{ color: colors.gray_2 }}
							className="text-[12px] font-medium cursor-default"
						>
							21 Yrs
						</h2>
					</div>

					{/* residence */}
					<div className="w-full flex justify-between items-center mb-2">
						<h1
							style={{ color: colors.white_1 }}
							className="text-[12px] font-semibold cursor-default"
						>
							Residence:
						</h1>

						<h2
							style={{ color: colors.gray_2 }}
							className="text-[12px] font-medium cursor-pointer"
							onClick={() => {
								window.open("https://www.google.com/search?q=Pakistan");
							}}
						>
							Pakistan
						</h2>
					</div>

					{/* city */}
					<div className="w-full flex justify-between items-center">
						<h1
							style={{ color: colors.white_1 }}
							className="text-[12px] font-semibold cursor-default"
						>
							City:
						</h1>

						<h2
							style={{ color: colors.gray_2 }}
							className="text-[12px] font-medium cursor-pointer"
							onClick={() => {
								window.open("https://www.google.com/search?q=Islamabad");
							}}
						>
							Islamabad
						</h2>
					</div>
				</div>

				{/* __Divider__ */}
				<Component.Divider color={colors.gray_3} className="mx-8" />

				{/* LANGUAGE CONTAINER */}
				<div className="px-8 py-6 w-full flex items-center justify-around">
					<Component.ProgresssCircle percentage={100} language={"Urdu"} />
					<Component.ProgresssCircle percentage={95} language={"Punjabi"} />
					<Component.ProgresssCircle percentage={80} language={"English"} />
				</div>

				{/* __Divider__ */}
				<Component.Divider color={colors.gray_3} className="mx-8" />

				{/* TECHNOLOGIES CONTAINER */}
				<div className="px-8 py-6">
					{DETAILS_TECHNOLOGY?.map?.(({ tech, percent }, index) => (
						<div key={index} className="my-2">
							<Component.ProgresssBar technology={tech} percentage={percent} />
						</div>
					))}
				</div>

				{/* __Divider__ */}
				<Component.Divider color={colors.gray_3} className="mx-8" />

				{/* SKILLS CONTAINER */}
				<div className="px-8 py-6">
					{DETAILS_SKILLS?.map?.(({ name }, index) => (
						<div
							key={index}
							className="my-2 w-full flex items-center cursor-default"
						>
							<BiCheckDouble color={colors.blue_4} size={18} />
							<h1
								style={{ color: colors.gray_2 }}
								className="text-[12px] font-medium ml-4 duration-100 ease-in hover:opacity-80"
							>
								{name}
							</h1>
						</div>
					))}
				</div>

				{/* __Divider__ */}
				<Component.Divider color={colors.gray_3} className="mx-8" />

				{/* CV DOWNLOAD BUTTON */}
				<div className="p-8 py-6">
					<a
						href={MY_RESUME}
						download={"AbdulHaseeb's Resume.pdf"}
						style={{
							color: colors.gray_2,
						}}
						className="w-fit h-fit flex items-center text-base font-semibold uppercase tracking-wide hover:!text-white duration-300 ease-in-out"
					>
						Download CV &nbsp; <GiCloudDownload />
					</a>
				</div>
			</div>

			{/* BOTTOM CONTAINER */}
			<div
				style={{
					backgroundColor: colors.black_2_,
				}}
				className="DetailsTop sticky h-[30px] w-full bottom-0 left-0 flex justify-center items-center z-40"
			>
				{SOCIAL_ACCOUNTS?.map?.(({ link, icon }, index) => (
					<a
						key={index}
						href={link}
						target="_blank"
						rel="noopener noreferrer"
						style={{ color: colors.gray_2 }}
						className="hover:!text-white duration-200 ease-in mx-1"
					>
						{icon}
					</a>
				))}
			</div>
		</div>
	);
}
