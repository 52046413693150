import React from "react";
import colors from "../../styles/colors";
import * as Component from "..";
import { RESUME_EDUCATION_LIST, RESUME_EXPERIENCE_LIST } from "../../data";

export default function Resume({ reference }) {
	return (
		<div
			ref={reference}
			className="mb-5 pt-10 px-2 lg:px-6 flex flex-row flex-wrap select-none"
		>
			{/* education */}
			<div className="flex-1 flex flex-col px-2 min-w-[400px] mb-10">
				<h1
					style={{ color: colors.white_1 }}
					className="text-lg font-semibold tracking-wide mb-3"
				>
					Education
				</h1>

				{RESUME_EDUCATION_LIST?.map?.(
					({ work, institute, duration, location, position, last }, index) => (
						<Component.CardResume
							key={index}
							last={last}
							work={work}
							institute={institute}
							duration={duration}
							location={location}
							position={position}
						/>
					)
				)}
			</div>

			{/* experience */}
			<div className="flex-1 flex flex-col px-2 min-w-[400px] mb-10">
				<h1
					style={{ color: colors.white_1 }}
					className="text-lg font-semibold tracking-wide mb-3"
				>
					Experience
				</h1>

				{RESUME_EXPERIENCE_LIST?.map?.(
					({ work, institute, duration, location, position, last }, index) => (
						<Component.CardResume
							key={index}
							last={last}
							eduType={false}
							work={work}
							institute={institute}
							duration={duration}
							location={location}
							position={position}
						/>
					)
				)}
			</div>
		</div>
	);
}
