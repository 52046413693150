import React, { useEffect, useRef, useState } from "react";
import "./ProgressCircle.css";
import colors from "../../../styles/colors";

export default function ProgressCircle({
	percentage,
	language,
	size,
	percentageFontSize,
	languageFontSize,
	percentStrokeColor,
	baseStrokeColor,
}) {
	const [percentageAnimate, setPercentageAnimate] = useState(0);

	const progressRef = useRef();
	const fillRef = useRef();

	useEffect(() => {
		const animatePercentage = (i) => {
			if (i > percentage) {
				return;
			}
			setPercentageAnimate(i);
			setTimeout(() => {
				animatePercentage(i + 1);
			}, 15);
		};

		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					// fillRef.current.classList.add("percent");
					animatePercentage(0);
					observer.unobserve(entry.target);
				}
			});
		});

		observer.observe(progressRef.current);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			ref={progressRef}
			className="h-fit w-fit flex flex-col items-center justify-center cursor-default duration-100 ease-in hover:opacity-80"
		>
			{/* circle */}
			<div className="relative h-fit w-fit flex items-center justify-center">
				<svg
					width={size ? size : "80"}
					height={size ? size : "80"}
					viewBox={`0 0 ${size ? size : "80"} ${size ? size : "80"}`}
					className="-rotate-90"
				>
					<circle
						cx={size ? size / 2 : "40"}
						cy={size ? size / 2 : "40"}
						r={size ? size / 2 - 9 : "31"}
						fill="none"
						stroke={baseStrokeColor ? baseStrokeColor : colors.white_1}
						strokeWidth="4"
					/>
					<circle
						ref={fillRef}
						className="empty "
						cx={size ? size / 2 : "40"}
						cy={size ? size / 2 : "40"}
						r={size ? size / 2 - 9 : "31"}
						fill="none"
						stroke={percentStrokeColor ? percentStrokeColor : colors.blue_4}
						strokeWidth="4"
						pathLength="100"
						strokeDasharray={100}
						strokeDashoffset={100 - percentageAnimate}
					/>
				</svg>

				{/* percentage */}
				<h1
					style={{ color: colors.white_1, fontSize: percentageFontSize }}
					className="absolute text-base"
				>
					{percentageAnimate}%
				</h1>
			</div>

			{/* language */}
			<h1
				style={{ color: colors.white_1, fontSize: languageFontSize }}
				className="text-xs font-medium mt-2"
			>
				{language}
			</h1>
		</div>
	);
}
