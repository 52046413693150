import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import colors from "../../../styles/colors";
import "./ProgressBar.css";

export default function ProgressBar({
	percentage = 100,
	technology,
	size = 4,
	technologyFontSize,
	percentageFontSize,
	percentStrokeColor = colors.blue_4,
	baseStrokeColor = colors.white_2,
}) {
	const [percentageAnimate, setPercentageAnimate] = useState(0);
	const progressRef = useRef();
	const fillRef = useRef();

	useEffect(() => {
		const animatePercentage = (i) => {
			if (i > percentage) {
				return;
			}
			setPercentageAnimate(i);
			setTimeout(() => {
				animatePercentage(i + 1);
			}, 15);
		};

		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					// fillRef.current.classList.add("fill");
					animatePercentage(0);
					observer.unobserve(entry.target);
				}
			});
		});

		observer.observe(progressRef.current);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="ProgressBar cursor-default duration-100 ease-in hover:opacity-80">
			{/* HEADINGS */}
			<div className="w-full flex justify-between mb-2">
				{/* technologo */}
				<h1
					style={{ color: colors.white_1, fontSize: technologyFontSize }}
					className="text-[12px] font-semibold"
				>
					{technology}
				</h1>

				{/* percentage */}
				<h2
					style={{ color: colors.gray_2, fontSize: percentageFontSize }}
					className="text-[12px] font-medium"
				>
					{percentageAnimate}%
				</h2>
			</div>

			{/* BAR */}
			<div
				ref={progressRef}
				style={{ height: size, backgroundColor: baseStrokeColor }}
				className="base w-full"
			>
				<div
					style={{
						height: size,
						width: percentageAnimate + "%",
						backgroundColor: percentStrokeColor,
					}}
					ref={fillRef}
					className="empty"
				></div>
			</div>
		</div>
	);
}
