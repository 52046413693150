import React from "react";
import colors from "../../../styles/colors";
import "./CardResume.css";
import { MdLocationOn } from "react-icons/md";
import { HiBuildingOffice2 } from "react-icons/hi2";
import { FaGraduationCap, FaUserCheck } from "react-icons/fa";
import { BiCodeAlt } from "react-icons/bi";

export default function CardResume({
	eduType = true,
	last = false,
	work,
	institute,
	duration,
	location,
	position,
}) {
	return (
		<div
			style={{
				borderLeft: "2px solid",
				borderColor: colors.gray_4,
			}}
			className={`card-resume relative min-w-[300px] p-4  
			${last === true && "pb-0"}
			`}
		>
			{/* card inner */}
			<div
				style={{
					backgroundColor: colors.black_2_,
					color: colors.gray_2,
				}}
				className="card-resume-inner min-h-[170px] rounded-sm px-7 py-7 overflow-hidden z-20 flex-1 shadow-lg"
			>
				{/* work & duration*/}
				<div className="flex justify-between">
					<h1
						style={{ color: colors.white_1 }}
						className="text-sm font-medium mb-3 w-2/5 sm:w-1/2"
					>
						{work}
					</h1>

					<h1
						style={{ color: colors.white_1, borderColor: colors.blue_4 }}
						className="h-fit text-sm mb-1 py-1 px-3 border-2 rounded-full"
					>
						{duration}
					</h1>
				</div>

				{/* institute */}
				<h1
					style={{ color: colors.gray_2 }}
					className="text-sm flex items-center"
				>
					{eduType === true ? (
						<FaGraduationCap />
					) : (
						<HiBuildingOffice2 className="mr-[2px]" />
					)}{" "}
					&nbsp; {institute}
				</h1>

				{/* position */}
				<h1
					style={{ color: colors.gray_2 }}
					className="text-sm flex items-center"
				>
					{eduType === true ? (
						<FaUserCheck />
					) : (
						<BiCodeAlt strokeWidth={0.5} size={16} />
					)}{" "}
					&nbsp; {position}
				</h1>

				{/* location */}
				<h1
					style={{ color: colors.gray_2 }}
					className="text-sm flex items-center"
				>
					<MdLocationOn
						size={16}
						className={`${eduType === true ? "-ml-[3px]" : ""}`}
					/>{" "}
					&nbsp; {location}
				</h1>
			</div>

			{/* card line */}
			<div
				style={{
					borderTop: "2px solid",
					borderColor: colors.gray_4,
				}}
				className="card-resume-line absolute top-0 left-0 w-2 z-50"
			></div>
		</div>
	);
}
