import React, { useEffect, useState } from "react";
import colors from "../../styles/colors";
import "./Banner.css";
import {
	BANNER_FIELDS,
	BANNER_MOBILE_FIELDS,
	BANNER_PROFILE_IMAGE,
} from "../../data";

export default function Banner({ reference }) {
	const [text, setText] = useState("");

	useEffect(() => {
		const fillFields = (index) => {
			if (index >= BANNER_FIELDS.length) {
				index = 0;
			}
			setTimeout(() => {
				fillChars(BANNER_FIELDS[index], 0, index + 1);
			}, 1000);
		};

		const fillChars = (field, index, mainIndex) => {
			if (index >= field.length) {
				setTimeout(() => {
					removeChars(field, index, mainIndex);
				}, 2000);
				return;
			}
			setText(field.substring(0, index + 1));
			setTimeout(() => {
				fillChars(field, index + 1, mainIndex);
			}, 150);
		};

		const removeChars = (field, index, mainIndex) => {
			if (index <= 0) {
				fillFields(mainIndex);
				return;
			}
			setText(field.substring(0, index - 1));
			setTimeout(() => {
				removeChars(field, index - 1, mainIndex);
			}, 30);
		};

		setTimeout(() => {
			fillFields(0);
		}, 2000);

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			ref={reference}
			className="Banner relative mb-5 pt-3 px-3 lg:px-7 lg:pt-7 xl:pt-10 select-none"
		>
			<div className="banner-bg absolute w-full h-[100px] top-0 left-0 flex items-end">
				<div
					style={{ backgroundColor: colors.black_4 }}
					className="h-[85px] w-full mx-20 rounded-lg opacity-90 max-xl:!hidden"
				></div>
			</div>

			<div
				style={{ boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)" }}
				className="banner-internal h-[250px] xl:h-[350px] relative p-5 flex"
			>
				<img
					style={{
						filter:
							"drop-shadow(0px 0px 1px rgb(255 255 255 / 0.9)) drop-shadow(0px 0px 1px rgb(255 255 255 / 0.9))",
					}}
					src={BANNER_PROFILE_IMAGE}
					alt="bg_image"
					className="h-[365px] absolute bottom-0 right-8 z-50 max-xl:!hidden"
				/>

				<div className="m-auto text-center w-11/12 md:w-2/3 xl:text-left xl:mx-0 xl:w-3/4 xl:max-w-[550px] xl:pl-[5%]">
					<h1
						style={{ color: colors.white_1 }}
						className="text-3xl sm:text-4xl xl:text-5xl font-black"
					>
						I build things on the internet !
					</h1>

					<h1
						style={{ color: colors.white_1 }}
						className="text-xl mt-5 duration-300 tracking-wider hidden md:block"
					>
						<span
							style={{ color: colors.blue_4 }}
							className="text-2xl font-extralight align-middle"
						>
							{"< > "}
						</span>
						I develop {text}
						<span className="text-xl font-extralight">|</span>
						<span
							style={{ color: colors.blue_4 }}
							className="text-xl font-extralight align-middle"
						>
							{" </>"}
						</span>
					</h1>

					<div className="flex items-center justify-center flex-wrap mt-5">
						{BANNER_MOBILE_FIELDS?.map?.((field, index) => (
							<h1
								key={index}
								style={{ color: colors.white_1 }}
								className="text-base duration-300 mx-1 tracking-wider md:hidden bg-black bg-opacity-50 rounded capitalize"
							>
								<span
									style={{ color: colors.blue_4 }}
									className="text-xl font-extralight align-middle"
								>
									{"| "}
								</span>
								{field.substring(0, field.length - 1)}
								<span
									style={{ color: colors.blue_4 }}
									className="text-xl font-extralight align-middle"
								>
									{" |"}
								</span>
							</h1>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}
