import React, { useState } from "react";
import "./Navbar.css";
import colors from "../../styles/colors";
import * as Component from "..";
import { BiDotsVertical } from "react-icons/bi";

export default function Navbar() {
	const [showSidebar, setShowSidebar] = useState(false);
	const [showDetails, setShowDetails] = useState(false);
	return (
		<div
			style={{
				backgroundColor: colors.black_5,
			}}
			className="Navbar w-full h-[60px] lg:hidden flex items-center px-6"
		>
			{/* Backdrop */}
			<Component.Backdrop show={showDetails || showSidebar} />

			{/* Buttons */}
			<div
				className={`Sidebar-Button z-50 text-white 
				${showDetails ? "opacity-0" : "opacity-100"} 
				${showSidebar ? "sBtnRight" : "sBtnLeft"}`}
				onClick={() => setShowSidebar(!showSidebar)}
			>
				<Component.HamburgerIcon showIcon={showSidebar} color={"white"} />
			</div>

			{/* <h1 className="ml-auto w-fit text-white text-sm font-medium font-sans">
				{"<abh/>"}
			</h1> */}

			<div
				className={`Details-Button z-50 ml-auto text-white
				${showSidebar ? "opacity-0" : "opacity-100"}
				${showDetails ? "dBtnLeft" : "dBtnRight"}`}
				onClick={() => setShowDetails(!showDetails)}
			>
				<BiDotsVertical />
			</div>

			{/* Containers */}
			<div
				className={`Sidebar-Container absolute z-40 h-full top-0 left-0 
				${showSidebar ? "sidebarRight" : "sidebarLeft"}`}
			>
				<Component.Sidebar from_nav={true} setShowSidebar={setShowSidebar} />
			</div>

			<div
				className={`Details-Container absolute z-40 h-full top-0 right-0 
				${showDetails ? "detailsLeft" : "detailsRight"}`}
			>
				<Component.Details from_nav={true} setShowDetails={setShowDetails} />
			</div>
		</div>
	);
}
