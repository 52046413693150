import React from "react";
import colors from "../../styles/colors";

export default function Backdrop({ show }) {
	return (
		<div
			style={{ backgroundColor: colors.blue_2 }}
			className={`Backdrop absolute top-0 left-0 z-30 h-full w-full transition-opacity duration-500 
			${show === true ? "opacity-50" : "opacity-0 hidden"}`}
		></div>
	);
}
