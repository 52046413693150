import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	component: "",
};

export const scrollSlice = createSlice({
	name: "scroll",
	initialState,
	reducers: {
		scrollTo: (state, action) => {
			state.component = action.payload;
		},
	},
});

export const { scrollTo } = scrollSlice.actions;
export const selectComponent = (state) => state.scroll;
export default scrollSlice.reducer;
