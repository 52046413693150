// icon imports
import {
	IoLogoLinkedin,
	IoLogoGithub,
	IoLogoTwitter,
	IoLogoFacebook,
} from "react-icons/io";

// ---------------------------------------------------------

// BANNER
export const BANNER_FIELDS = [
	"website UIs.",
	"website backend.",
	"APIs.",
	"mobile apps.",
];
export const BANNER_MOBILE_FIELDS = [
	"website UIs.",
	"mobile apps.",
	"website backend.",
	"APIs.",
];
export { default as BANNER_PROFILE_IMAGE } from "../assets/profile/profile_front.png";

// SERVICES
export const SERVICES_LIST = [
	{
		heading: "Web Development",
		start:
			"I offer my services for building custom websites and web applications that cater to the requirements. ",
		points: [
			"Frontend using HTML, CSS, Javascript, React Js, Redux etc.",
			"Node Js as a backend technology.",
			"Databases like Firebase, MongoDB and MySQL.",
		],
		end: "This includes the user interface, all functionalities, and a final product that is fully optimized for performance.",
	},
	{
		heading: "API Development",
		start:
			"I also design and build RESTful APIs as per the specifications using following technologies: ",
		points: ["Node Js", "Express", "MongoDB", "JWT"],
		end: "In addition, this encompasses user authentication, data management, and integration with third-party services.",
	},
	{
		heading: "Mobile Apps",
		start:
			"My services also include mobile app development using React Native. Following are the technologies I use:",
		points: [
			"React Native",
			"EXPO",
			"Tailwind for styling.",
			"React Native Paper for styled components.",
			"Axios for HTTP Requests.",
		],
		end: "I develop mobile applications having advanced features.",
	},
];

// RESUME
export const RESUME_EDUCATION_LIST = [
	{
		work: "Bachelor of Science in Software Engineering (BSSE)",
		institute: "Comsats University Islamabad",
		duration: "2019 - 2023",
		location: "Islamabad",
		position: "Student",
	},
	{
		work: "Intermediate of Computer Science (ICS)",
		institute: "Punjab Group of Colleges",
		duration: "2017 - 2019",
		location: "Mandibahauddin",
		position: "Student",
	},
	{
		last: true,
		work: "Matric",
		institute: "Usman Science School",
		duration: "2015 - 2017",
		location: "Mandibahauddin",
		position: "Student",
	},
];
export const RESUME_EXPERIENCE_LIST = [
	{
		work: "MERN Stack Developer",
		institute: "NESL-IT",
		duration: "Aug, 2023 - Ongoing",
		location: "Rawalpindi",
		position: "MERN Stack Developer",
	},
	{
		last: true,
		work: "Freelance Work",
		institute: "Fiverr",
		duration: "Sep, 2022 - Ongoing",
		location: "Remote",
		position: "Full-stack Developer",
	},
];

// WORKS
export const WORKS_PROJECTS_LIST = [
	{
		name: "KidverCity Website",
		description: "A website for an Educational Institute.",
		link: "https://kidver-city.netlify.app/",
		image: require("../assets/project/kidvercity_square.png"),
		tech: "react",
	},
	{
		name: "AirSpace (Web Demo)",
		description:
			"AirSpace is an IOT based parking system. It was my FYP project. It contained following main modules. \n-Web Admin Portal(React Js)\n-Mobile App(Expo)\n-Api(Node Js, Express Js, MongoDB)\n-Vehicle Detection and Barrier Control(IOT and ML)",
		link: "https://abh-fyp-demo.netlify.app/dashboard",
		image: require("../assets/project/fyp_square.png"),
		tech: "mern",
	},
	{
		name: "KidverCity Admin Portal",
		description: "Admin Portal website for an Educational Institute.",
		link: "https://kidver-city.netlify.app/dashboard",
		image: require("../assets/project/kidvercity_admin.jpg"),
		tech: "react",
	},
	{
		name: "Pocketclass",
		description:
			"An online teaching platform containing features like (Chat, Class Booking, Payments, Admin Support).",
		link: "https://pocketclass.ca",
		image: require("../assets/project/pocketclass.png"),
		tech: "next",
	},
	{
		name: "Jones Transportation",
		description: "An expense tracking application for a transport company.",
		link: "https://jonestrucking.netlify.app/landing",
		image: require("../assets/project/rf_jonesTransportation.png"),
		tech: "react",
	},
	{
		name: "Crypto Blog",
		description:
			"A blog website having features like posts with images and text, like and dislike feature, deleting a post etc.",
		link: "https://crypto-blog-34272.web.app/",
		image: require("../assets/project/rf_cryptoBlog_landscape.png"),
		tech: "react",
	},
	{
		name: "Vig Sports",
		description:
			"A static website for sports/crypto related blogs, wallet connection and updated softwares.",
		link: "https://vig-sports.netlify.app/",
		image: require("../assets/project/vig.png"),
		tech: "react",
	},
	{
		name: "University Management System",
		description:
			"Complete management system and online portal for tracking marks, attendance, records, etc.",
		link: "http://abh.infinityfreeapp.com/project2/",
		image: require("../assets/project/pms_ums.jpg"),
		tech: "php",
	},
	{
		name: "Punctual",
		description:
			"Attendance management and chat system for a school having two portals, one for teachers and other for parents.",
		link: "https://punctual.netlify.app/",
		image: require("../assets/project/rf_punctual_landscape.png"),
		tech: "react",
	},
	{
		name: "Profile App",
		description:
			"An online system to display user portfolio, projects and contact information.",
		link: "https://profile-proj.netlify.app/",
		image: require("../assets/project/rf_profile.png"),
		tech: "react",
	},
	{
		name: "Ecommerce App",
		description:
			"An ecommerce store with features like buying products, rating, commenting, payments, and admin panel for managing the products.",
		link: "https://ecomm-mart.netlify.app/",
		image: require("../assets/project/mern_ecommerce.png"),
		tech: "mern",
	},
	{
		id: 11,
		more: true,
		name: "More Projects",
		description: "",
		link: "",
		image: "",
		tech: "",
	},
];

// CONTACT
export const CONTACT_LIST = [
	[
		{ name: "Country", value: "Pakistan" },
		{ name: "City", value: "Islamabad" },
		{ name: "Zip Code", value: "44000" },
	],
	[
		{ name: "Email", value: "abdulhaseeb2115@gmail.com" },
		{ name: "Phone", value: "+923496384386" },
		{ name: "Whatsapp", value: "+923496384386" },
	],
];

// DETAILS
export { default as DETAILS_PROFILE_IMAGE } from "../assets/profile/profile_side.jpg";
export const DETAILS_TECHNOLOGY = [
	{
		tech: "HTML , CSS",
		percent: 90,
	},
	{
		tech: "Javascript , Typescript",
		percent: 85,
	},
	{
		tech: "React Js , Next Js",
		percent: 80,
	},
	{
		tech: "PHP , Laravel",
		percent: 60,
	},
	{
		tech: "React Native , Expo CLI",
		percent: 75,
	},
];
export const DETAILS_SKILLS = [
	{
		name: "Node Js , Express Js",
	},
	{
		name: "MongoDB , Firebase , MySQL",
	},
	{
		name: "Tailwind , Bootstrap , JQuery",
	},
	{
		name: "Material UI , Ant Design",
	},
	{
		name: "Chart Js , Apex Charts",
	},
	{
		name: "GIT & Github",
	},
];

// SOCIAL
export const GITHUB_REPOS_URL =
	"https://github.com/abdulhaseeb2115?tab=repositories";
export const SOCIAL_ACCOUNTS = [
	{
		link: "https://www.linkedin.com/in/abdul-haseeb-8b7a3321b/",
		icon: <IoLogoLinkedin size={16} />,
	},
	{
		link: "https://github.com/abdulhaseeb2115",
		icon: <IoLogoGithub size={16} />,
	},
	{
		link: "https://twitter.com/AbdulHaseeb2115",
		icon: <IoLogoTwitter size={16} />,
	},
	{
		link: "https://www.facebook.com/abh2115/",
		icon: <IoLogoFacebook size={16} />,
	},
];

// RESUME
export { default as MY_RESUME } from "../assets/resume/resume.pdf";
