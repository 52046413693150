import React from "react";
import { motion } from "framer-motion";
import colors from "../../../styles/colors";
import "./CardWork.css";
import { BiChevronRight } from "react-icons/bi";

export default function CardWork({ project }) {
	return (
		<motion.div
			className="p-2 aspect-square w-1/2 md:w-1/3 lg:min-w-[250px] lg:w-1/2 xl:w-1/3"
			layout
			transition={{ duration: 0.5, ease: "easeInOut" }}
		>
			<div
				style={{
					boxShadow: "0px 0px 12px rgb(50 50 50 /0.4)",
				}}
				className="cardWork--inner relative rounded-sm h-full overflow-hidden z-20 brightness-[0.85] duration-300 ease-in-out hover:brightness-100 bg-zinc-800"
			>
				<img
					src={project.image}
					alt="project_image"
					className="h-full object-contain hover:scale-110 duration-300 ease-in-out rounded-sm"
				/>

				<div
					style={{
						backgroundColor: colors.black_2_,
					}}
					className="cardWork--desc absolute w-full max-md:h-fit h-3/5 bottom-0 left-0 translate-y-full duration-500 ease-in-out px-5 py-7 flex flex-col"
				>
					<h1
						style={{ color: colors.white_1 }}
						className="font-medium tracking-wide"
					>
						{project.name}
					</h1>

					<h1
						style={{ color: colors.gray_2 }}
						className="text-sm font-lighter mt-3"
					>
						<span className="hidden 2xl:block">
							{project.description.toString().length > 90
								? project.description.toString().slice(0, 90) + " . . ."
								: project.description.toString()}
						</span>

						<span className="hidden lg:block 2xl:hidden">
							{project.description.toString().length > 50
								? project.description.toString().slice(0, 50) + " . . ."
								: project.description.toString()}
						</span>

						<span className="hidden md:block lg:hidden">
							{project.description.toString().length > 30
								? project.description.toString().slice(0, 30) + " . . ."
								: project.description.toString()}
						</span>
					</h1>

					<a
						href={project.link}
						target="_blank"
						rel="noreferrer"
						className="w-fit py-1 mt-auto flex items-center text-xs uppercase tracking-wider font-semibold hover:!text-white duration-200 ease-in-out"
						style={{ color: colors.blue_4 }}
					>
						Visit Project
						<BiChevronRight size={20} className={"-mt-[1px]"} />
					</a>
				</div>
			</div>
		</motion.div>
	);
}
