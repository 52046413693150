import React, { useState } from "react";
import colors from "../../styles/colors";
import * as Component from "../../components";
import "./HomeScreen.css";

export default function HomeScreen() {
	const [showMenu, setShowMenu] = useState(false);

	return (
		<div
			style={{ backgroundColor: colors.black_3 }}
			className="Homepage h-screen w-screen overflow-hidden flex justify-center lg:px-8 lg:py-4"
		>
			<div className="relative h-full w-full lg:rounded-md lg:overflow-hidden">
				<Component.Navbar />

				<div className="w-full h-[calc(100%_-_60px)] lg:h-full flex justify-center">
					{/* sidebar */}
					<div
						className={`Sidebar-Menu max-lg:!hidden flex flex-col 
                        ${showMenu ? "menuRight" : "menuLeft"}`}
					>
						<div
							style={{ height: 50, backgroundColor: colors.black_2_ }}
							className={`w-full flex items-center justify-end pr-[22.5px]`}
						>
							<Component.HamburgerIcon
								showIcon={showMenu}
								color={"white"}
								onClick={() => setShowMenu(!showMenu)}
							/>
						</div>
						{/* abh portfolio */}
						<Component.Sidebar from_nav={false} />
					</div>

					{/* main */}
					<Component.Main />

					{/* details */}
					<div className="max-lg:!hidden h-full">
						<Component.Details from_nav={false} />
					</div>
				</div>
			</div>
		</div>
	);
}
