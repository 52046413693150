import React from "react";
import "./HamburgerIcon.css";

export default function HamburgerIcon({ showIcon, color, ...params }) {
	return (
		<div
			id="hamburger-icon"
			className={`${showIcon === true && "open"}`}
			{...params}
		>
			<span style={{ backgroundColor: color }}></span>
			<span style={{ backgroundColor: color }}></span>
			<span style={{ backgroundColor: color }}></span>
		</div>
	);
}
