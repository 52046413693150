import React, { useState } from "react";
import colors from "../../styles/colors";
import "./AvailableCircle.css";

export default function AvailableCircle() {
	const [showText, setShowText] = useState(false);
	return (
		<div
			className="pulsating-circle"
			onMouseEnter={() => setShowText(true)}
			onMouseLeave={() => setShowText(false)}
		>
			{/* available text */}
			<div
				style={{ color: colors.gray_2 }}
				className={`absolute text-[10px] w-20 left-full ml-3 mt-6 duration-500 ease-in-out overflow-hidden ${
					showText ? "opacity-100" : "opacity-0"
				}`}
			>
				I am available for work.
			</div>
		</div>
	);
}
