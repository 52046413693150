import React from "react";
import colors from "../../../styles/colors";
import "./CardContact.css";

export default function CardContact({ item }) {
	return (
		<div className="card-contact min-w-[300px] flex-1 p-2">
			<div
				style={{
					backgroundColor: colors.black_2_,
					color: colors.gray_2,
				}}
				className="card-contact-inner relative rounded-sm h-full px-7 py-7 overflow-hidden z-20"
			>
				{item?.map?.(({ name, value }, index) => (
					<div key={index} className="flex justify-between items-center">
						<h1
							style={{ color: colors.white_1 }}
							className="text-sm font-medium"
						>
							{name}:
						</h1>

						<p className="text-sm">{value}</p>
					</div>
				))}
			</div>
		</div>
	);
}
