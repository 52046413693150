import React from "react";
import colors from "../../styles/colors";
import * as Component from "..";
import { SERVICES_LIST } from "../../data";

export default function Services({ reference }) {
	return (
		<div ref={reference} className="mb-5 pt-10 px-2 lg:px-6 select-none">
			<div className="w-full">
				<h1
					style={{ color: colors.white_1 }}
					className="text-lg font-semibold tracking-wide pl-2"
				>
					My Services
				</h1>

				{/* cards */}
				<div className="w-full mt-5 items-stretch flex flex-row justify-between flex-wrap">
					{SERVICES_LIST?.map?.(({ heading, start, points, end }, index) => (
						<Component.CardService
							heading={heading}
							start={start}
							points={points}
							end={end}
							key={index}
						/>
					))}
				</div>
			</div>
		</div>
	);
}
