import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectComponent } from "../../features/scrollSlice";
import colors from "../../styles/colors";
import * as Component from "..";

export default function Main() {
	const { component } = useSelector(selectComponent);

	const bannerRef = useRef();
	const servicesRef = useRef();
	const resumeRef = useRef();
	const worksRef = useRef();
	const contactRef = useRef();

	const scroll = (secRef) => {
		secRef.current?.scrollIntoView({
			behavior: "smooth",
			block: "nearest",
		});
	};

	useEffect(() => {
		const scrollToComponent = () => {
			switch (component) {
				case "banner":
					scroll(bannerRef);
					break;
				case "services":
					scroll(servicesRef);
					break;
				case "resume":
					scroll(resumeRef);
					break;
				case "works":
					scroll(worksRef);
					break;
				case "contact":
					scroll(contactRef);
					break;
				default:
					break;
			}
		};
		scrollToComponent();
	}, [component]);

	return (
		<div
			style={{ backgroundColor: colors.black_4 }}
			className="Main max-w-[1200px] h-full flex-1 overflow-auto scroll-smooth"
		>
			{/* Banner */}
			<Component.Banner reference={bannerRef} />

			{/* Services */}
			<Component.Services reference={servicesRef} />

			{/* Resume */}
			<Component.Resume reference={resumeRef} />

			{/* Works */}
			<Component.Works reference={worksRef} />

			{/* Contact */}
			<Component.Contact reference={contactRef} />
		</div>
	);
}
